// Case sensitive. needs to be the same as auth0 dashboard
export const enum ROLES {
    SUPER_ADMIN = "Super Admin",
    OWNER = "Owner",
    ADMIN = "Admin",
    RE_AGENT = "RE Agent",
    LOAN_OFFICER = "Loan Officer",
};

export const USER_ROLES = {
    SUPER_ADMIN: "Super Admin",
    OWNER: "Owner",
    ADMIN: "Admin",
    RE_AGENT: "RE Agent",
    LOAN_OFFICER: "Loan Officer",
};

// Case sensitive. needs to be the same as auth0 dashboard
export enum PERMISSIONS {
    LEAD_ACCESS = "read:lead_tab",
    LEAD_READ = "read:lead",
    LEAD_READ_OWN = "read:lead_own",
    LEAD_CREATE = "create:lead",
    LEAD_UPDATE = "update:lead",
    LEAD_DELETE = "delete:lead",
    LEAD_UPDATE_FLAG = "update:lead_flag",
    LEAD_UPDATE_UNFLAG = "update:lead_unflag",
    LEAD_UPDATE_AGENT = "update:lead_agent",
    LEAD_UPDATE_AGENT_OWN = "update:lead_agent_own",
    LEAD_UPDATE_LOAN_OFFICER = "update:lead_loan_officer",
    LEAD_UPDATE_LOAN_OFFICER_OWN = "update:lead_loan_officer_own",
    LEAD_UPDATE_CONVERT = "update:lead_convert",
    LEAD_READ_OVERALL = "read:lead_overall",
    LEAD_UPDATE_OVERALL = "update:lead_overall",
    LEAD_CREATE_CREDIT_SCORES = "create:lead_credit_scores",
    LEAD_UPDATE_CREDIT_SCORES = "update:lead_credit_scores",
    LEAD_DELETE_CREDIT_SCORES = "delete:lead_credit_scores",
    LEAD_READ_FINANCIAL = "read:lead_financial",
    LEAD_UPDATE_FINANCIAL = "update:lead_financial",
    LEAD_READ_DOCUMENT = "read:lead_document",
    LEAD_IMPORT_DOCUMENT = "import:lead_document",
    LEAD_DELETE_DOCUMENT = "delete:lead_document",
    LEAD_READ_NOTES = "read:lead_notes",
    LEAD_CREATE_NOTES = "create:lead_notes",
    LEAD_UPDATE_NOTES = "update:lead_notes",
    LEAD_DELETE_NOTES = "delete:lead_notes",
    LEAD_READ_CONVERSATION = "read:lead_conversation",
    LEAD_CREATE_CONVERSATION = "create:lead_conversation",

    CLIENT_ACCESS = "read:client_tab",
    CLIENT_READ = "read:client",
    CLIENT_READ_OWN = "read:client_own",
    CLIENT_CREATE = "create:client",
    CLIENT_UPDATE = "update:client",
    CLIENT_DELETE = "delete:client",
    CLIENT_UPDATE_AGENT = "update:client_agent",
    CLIENT_UPDATE_AGENT_OWN = "update:client_agent_own",
    CLIENT_UPDATE_LOAN_OFFICER = "update:client_loan_officer",
    CLIENT_UPDATE_LOAN_OFFICER_OWN = "update:client_loan_officer_own",
    CLIENT_UPDATE_FLAG = "update:client_flag",
    CLIENT_UPDATE_UNFLAG = "update:client_unflag",
    CLIENT_READ_OVERALL = "read:client_overall",
    CLIENT_UPDATE_OVERALL = "update:client_overall",
    CLIENT_CREATE_CREDIT_SCORES = "create:client_credit_scores",
    CLIENT_UPDATE_CREDIT_SCORES = "update:client_credit_scores",
    CLIENT_DELETE_CREDIT_SCORES = "delete:client_credit_scores",
    CLIENT_READ_PROGRAM = "read:client_program",
    CLIENT_CREATE_PROGRAM = "create:client_program",
    CLIENT_UPDATE_PROGRAM = "update:client_program",
    CLIENT_DELETE_PROGRAM = "delete:client_program",
    CLIENT_READ_FINANCIAL = "read:client_financial",
    CLIENT_UPDATE_FINANCIAL = "update:client_financial",
    CLIENT_READ_DOCUMENT = "read:client_document",
    CLIENT_IMPORT_DOCUMENT = "import:client_document",
    CLIENT_DELETE_DOCUMENT = "delete:client_document",
    CLIENT_READ_NOTES = "read:client_notes",
    CLIENT_CREATE_NOTES = "create:client_notes",
    CLIENT_DELETE_NOTES = "delete:client_notes",
    CLIENT_UPDATE_NOTES = "update:client_notes",
    CLIENT_READ_CONVERSATION = "read:client_conversation",
    CLIENT_CREATE_CONVERSATION = "create:client_conversation",

    ACTIVITY_ACCESS = "read:activity_tab",

    CAMPAIGN_ACCESS = "read:campaign_tab",
    CAMPAIGN_CREATE_BULK_MESSAGES = "create:campaign_bulk_messages",
    CAMPAIGN_READ_BULK_MESSAGES = "read:campaign_bulk_messages",
    CAMPAIGN_READ = "read:campaign",
    CAMPAIGN_CREATE = "create:campaign",
    CAMPAIGN_DELETE = "delete:campaign",
    CAMPAIGN_UPDATE = "update:campaign",
    CAMPAIGN_READ_TEMPLATES = "read:campaign_templates",
    CAMPAIGN_CREATE_TEMPLATES = "create:campaign_templates",
    CAMPAIGN_DELETE_TEMPLATES = "delete:campaign_templates",
    CAMPAIGN_UPDATE_TEMPLATES = "update:campaign_templates",

    USER_ACCESS = "read:user_tab",
    USER_CREATE = "create:user",
    USER_READ = "read:user",
    USER_UPDATE = "update:user",
    USER_DELETE = "delete:user",
    USER_UPDATE_ROLE_PERMISSIONS = "update:user_role_permissions",
    USER_UPDATE_PASSWORD = "update:user_password",

    CALENDAR_ACCESS = "read:calendar_tab",
    CALENDAR_READ_APPOINTMENTS = "read:calendar_appointments",
    CALENDAR_READ_APPOINTMENTS_OWN = "read:calendar_appointments_own",
    CALENDAR_CREATE_APPOINTMENTS = "create:calendar_appointments",
    CALENDAR_UPDATE_APPOINTMENTS = "update:calendar_appointments",
    CALENDAR_DELETE_APPOINTMENTS = "delete:calendar_appointments",
    CALENDAR_CREATE_APPOINTMENTS_SUPERIOR = "create:calendar_appointments_superior",
    CALENDAR_CREATE_APPOINTMENTS_INFERIOR = "create:calendar_appointments_inferior",
    CALENDAR_READ_APPOINTMENTS_ASSIGNED_BY = "read:calendar_appointments_assigned_by",
    CALENDAR_READ_TASKS = "read:calendar_tasks",
    CALENDAR_READ_TASKS_OWN = "read:calendar_tasks_own",
    CALENDAR_CREATE_TASKS = "create:calendar_tasks",
    CALENDAR_UPDATE_TASKS = "update:calendar_tasks",
    CALENDAR_DELETE_TASKS = "delete:calendar_tasks",
    CALENDAR_CREATE_TASKS_SUPERIOR = "create:calendar_tasks_superior",
    CALENDAR_CREATE_TASKS_INFERIOR = "create:calendar_tasks_inferior",
    CALENDAR_READ_TASKS_ASSIGNED_BY = "read:calendar_tasks_assigned_by",

    SETTINGS_ACCESS = "read:settings_tab",
    SETTINGS_READ_DROPDOWNS = "read:settings_dropdowns",
    SETTINGS_READ_ROUND_ROBIN = "read:settings_round_robin",
    SETTINGS_UPDATE_DROPDOWNS = "update:settings_dropdowns",
    SETTINGS_UPDATE_ROUND_ROBIN = "update:settings_round_robin",

    OTHER_AGENT_ASSIGNABLE = "other:agent_assignable",
    OTHER_AGENT_ROUND_ROBIN = "other:agent_round_robin",
}

/**
 * ACCESS PERMISSIONS ARRAY
 */
export const ACCESS_PERMISSIONS = [
    PERMISSIONS.LEAD_ACCESS,
    PERMISSIONS.CLIENT_ACCESS,
    PERMISSIONS.ACTIVITY_ACCESS,
    PERMISSIONS.CAMPAIGN_ACCESS,
    PERMISSIONS.USER_ACCESS,
    PERMISSIONS.CALENDAR_ACCESS,
    PERMISSIONS.SETTINGS_ACCESS,
];

/**
 * Permissions for LEADS pages
 */
export const LEAD_PERMISSIONS = {
    access: PERMISSIONS.LEAD_ACCESS,
    sections: [
        {
            title: "General",
            permissions: [
                {
                    text: "Access to: ",
                    dropdown_options: [
                        {
                            label: "All Leads",
                            value: PERMISSIONS.LEAD_READ,
                            markAll: true,
                        },
                        {
                            label: "Own Leads",
                            value: PERMISSIONS.LEAD_READ_OWN,
                        },
                    ],
                },
                PERMISSIONS.LEAD_CREATE,
                // PERMISSIONS.LEAD_UPDATE,
                PERMISSIONS.LEAD_DELETE,
                PERMISSIONS.LEAD_UPDATE_FLAG,
                PERMISSIONS.LEAD_UPDATE_UNFLAG,
                {
                    text: "Update Agent: ",
                    dropdown_options: [
                        {
                            label: "All Leads",
                            value: PERMISSIONS.LEAD_UPDATE_AGENT,
                            markAll: true,
                        },
                        {
                            label: "Own Leads",
                            value: PERMISSIONS.LEAD_UPDATE_AGENT_OWN,
                        },
                    ],
                },
                {
                    text: "Update Loan Officer: ",
                    dropdown_options: [
                        {
                            label: "All Leads",
                            value: PERMISSIONS.LEAD_UPDATE_LOAN_OFFICER,
                            markAll: true,
                        },
                        {
                            label: "Own Leads",
                            value: PERMISSIONS.LEAD_UPDATE_LOAN_OFFICER_OWN,
                        },
                    ],
                },
                PERMISSIONS.LEAD_UPDATE_CONVERT,
            ],
        },
        {
            title: "Overall",
            permissions: [
                PERMISSIONS.LEAD_READ_OVERALL,
                PERMISSIONS.LEAD_UPDATE_OVERALL,
                PERMISSIONS.LEAD_CREATE_CREDIT_SCORES,
                PERMISSIONS.LEAD_UPDATE_CREDIT_SCORES,
                PERMISSIONS.LEAD_DELETE_CREDIT_SCORES,
            ],
        },
        {
            title: "Financial Disclosure",
            permissions: [PERMISSIONS.LEAD_READ_FINANCIAL, PERMISSIONS.LEAD_UPDATE_FINANCIAL],
        },
        {
            title: "Documents",
            permissions: [
                PERMISSIONS.LEAD_READ_DOCUMENT,
                PERMISSIONS.LEAD_IMPORT_DOCUMENT,
                PERMISSIONS.LEAD_DELETE_DOCUMENT,
            ],
        },
        {
            title: "Notes",
            permissions: [
                PERMISSIONS.LEAD_READ_NOTES,
                PERMISSIONS.LEAD_CREATE_NOTES,
                PERMISSIONS.LEAD_UPDATE_NOTES,
                PERMISSIONS.LEAD_DELETE_NOTES,
            ],
        },
        {
            title: "Conversations",
            permissions: [PERMISSIONS.LEAD_READ_CONVERSATION, PERMISSIONS.LEAD_CREATE_CONVERSATION],
        },
    ],
};

/**
 * Permissions for CLIENTS pages
 */
export const CLIENT_PERMISSIONS = {
    access: PERMISSIONS.CLIENT_ACCESS,
    sections: [
        {
            title: "General",
            permissions: [
                {
                    text: "Access to: ",
                    dropdown_options: [
                        {
                            label: "All Clients",
                            value: PERMISSIONS.CLIENT_READ,
                            markAll: true,
                        },
                        {
                            label: "Own Clients",
                            value: PERMISSIONS.CLIENT_READ_OWN,
                        },
                    ],
                },
                PERMISSIONS.CLIENT_CREATE,
                // PERMISSIONS.CLIENT_UPDATE,
                PERMISSIONS.CLIENT_DELETE,
                {
                    text: "Update Agent: ",
                    dropdown_options: [
                        {
                            label: "All Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_AGENT,
                            markAll: true,
                        },
                        {
                            label: "Own Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_AGENT_OWN,
                        },
                    ],
                },
                {
                    text: "Update Loan Officer: ",
                    dropdown_options: [
                        {
                            label: "All Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_LOAN_OFFICER,
                            markAll: true,
                        },
                        {
                            label: "Own Clients",
                            value: PERMISSIONS.CLIENT_UPDATE_LOAN_OFFICER_OWN,
                        },
                    ],
                },
                PERMISSIONS.CLIENT_UPDATE_FLAG,
                PERMISSIONS.CLIENT_UPDATE_UNFLAG,
            ],
        },
        {
            title: "Overall",
            permissions: [
                PERMISSIONS.CLIENT_READ_OVERALL,
                PERMISSIONS.CLIENT_UPDATE_OVERALL,
                PERMISSIONS.CLIENT_CREATE_CREDIT_SCORES,
                PERMISSIONS.CLIENT_UPDATE_CREDIT_SCORES,
                PERMISSIONS.CLIENT_DELETE_CREDIT_SCORES,
            ],
        },
        {
            title: "Programs",
            permissions: [
                PERMISSIONS.CLIENT_READ_PROGRAM,
                PERMISSIONS.CLIENT_CREATE_PROGRAM,
                PERMISSIONS.CLIENT_UPDATE_PROGRAM,
                PERMISSIONS.CLIENT_DELETE_PROGRAM,
            ],
        },
        {
            title: "Financial Disclosure",
            permissions: [PERMISSIONS.CLIENT_READ_FINANCIAL, PERMISSIONS.CLIENT_UPDATE_FINANCIAL],
        },
        {
            title: "Documents",
            permissions: [
                PERMISSIONS.CLIENT_READ_DOCUMENT,
                PERMISSIONS.CLIENT_IMPORT_DOCUMENT,
                PERMISSIONS.CLIENT_DELETE_DOCUMENT,
            ],
        },
        {
            title: "Notes",
            permissions: [
                PERMISSIONS.CLIENT_READ_NOTES,
                PERMISSIONS.CLIENT_CREATE_NOTES,
                PERMISSIONS.CLIENT_DELETE_NOTES,
                PERMISSIONS.CLIENT_UPDATE_NOTES,
            ],
        },
        {
            title: "Conversations",
            permissions: [
                PERMISSIONS.CLIENT_READ_CONVERSATION,
                PERMISSIONS.CLIENT_CREATE_CONVERSATION,
            ],
        },
    ],
};

/**
 * Permissions for CAMPAIGNS pages
 */
export const CAMPAIGNS_PERMISSIONS = [
    PERMISSIONS.CAMPAIGN_READ_BULK_MESSAGES,
    PERMISSIONS.CAMPAIGN_CREATE_BULK_MESSAGES,
    PERMISSIONS.CAMPAIGN_READ,
    PERMISSIONS.CAMPAIGN_CREATE,
    PERMISSIONS.CAMPAIGN_DELETE,
    PERMISSIONS.CAMPAIGN_UPDATE,
    PERMISSIONS.CAMPAIGN_READ_TEMPLATES,
    PERMISSIONS.CAMPAIGN_CREATE_TEMPLATES,
    PERMISSIONS.CAMPAIGN_DELETE_TEMPLATES,
    PERMISSIONS.CAMPAIGN_UPDATE_TEMPLATES,
];

/**
 * Permissions for USERS pages
 */
export const USERS_PERMISSIONS = [
    PERMISSIONS.USER_READ,
    PERMISSIONS.USER_CREATE,
    PERMISSIONS.USER_UPDATE,
    PERMISSIONS.USER_DELETE,
    PERMISSIONS.USER_UPDATE_ROLE_PERMISSIONS,
    PERMISSIONS.USER_UPDATE_PASSWORD,
];

/**
 * Permissions for CALENDAR pages
 */
export const CALENDAR_PERMISSIONS = [
    {
        text: "View: ",
        dropdown_options: [
            {
                label: "All Appointments",
                value: PERMISSIONS.CALENDAR_READ_APPOINTMENTS,
                markAll: true,
            },
            {
                label: "Own Appointments",
                value: PERMISSIONS.CALENDAR_READ_APPOINTMENTS_OWN,
            },
        ],
    },
    PERMISSIONS.CALENDAR_CREATE_APPOINTMENTS,
    PERMISSIONS.CALENDAR_UPDATE_APPOINTMENTS,
    PERMISSIONS.CALENDAR_DELETE_APPOINTMENTS,
    PERMISSIONS.CALENDAR_CREATE_APPOINTMENTS_SUPERIOR,
    PERMISSIONS.CALENDAR_CREATE_APPOINTMENTS_INFERIOR,
    PERMISSIONS.CALENDAR_READ_APPOINTMENTS_ASSIGNED_BY,
    {
        text: "View: ",
        dropdown_options: [
            {
                label: "All Tasks",
                value: PERMISSIONS.CALENDAR_READ_TASKS,
                markAll: true,
            },
            {
                label: "Own Tasks",
                value: PERMISSIONS.CALENDAR_READ_TASKS_OWN,
            },
        ],
    },
    PERMISSIONS.CALENDAR_CREATE_TASKS,
    PERMISSIONS.CALENDAR_UPDATE_TASKS,
    PERMISSIONS.CALENDAR_DELETE_TASKS,
    PERMISSIONS.CALENDAR_CREATE_TASKS_SUPERIOR,
    PERMISSIONS.CALENDAR_CREATE_TASKS_INFERIOR,
    PERMISSIONS.CALENDAR_READ_TASKS_ASSIGNED_BY,
];

/**
 * Permissions for SETTINGS Pages
 */
export const SETTINGS_PERMISSIONS = [
    PERMISSIONS.SETTINGS_READ_DROPDOWNS,
    PERMISSIONS.SETTINGS_READ_ROUND_ROBIN,
    PERMISSIONS.SETTINGS_UPDATE_DROPDOWNS,
    PERMISSIONS.SETTINGS_UPDATE_ROUND_ROBIN,
];

/**
 * Mapper from permission name in auth0 to text to show on user's page
 */
export const PERMISSION_TITLE_MAPPER: any = {
    "read:lead / read:lead_own": "View All Leads // View Own Leads (Based on Role)",
    "create:lead": "Create New Leads",
    "delete:lead": "Delete Leads",
    "create:lead_import": "Import Leads",
    "update:lead_flag": "Red Flag Leads on Leads",
    "update:lead_unflag": "Remove flagged status on Leads",
    "update:lead_agent": "Edit Lead's Agent Assigned",
    "update:lead_loan_officer": "Update Lead's Loan Officer",
    "update:lead_convert": "Convert Lead to a Client",
    "read:lead_overall": "View Overall's Lead Tab",
    "update:lead_overall": "Edit Overall's Lead Tab Information",
    "create:lead_credit_scores": "Create New Credit Score in Lead",
    "update:lead_credit_scores": "Edit Credit Scores in Lead",
    "delete:lead_credit_scores": "Delete Credit Scores in Lead",
    "read:lead_financial": "View Financial Disclousure's Lead Tab",
    "update:lead_financial": "Edit Financial Disclousure's Lead Tab",
    "read:lead_document": "View Documents' Lead Tab",
    "import:lead_document": "Upload Documents in Leads",
    "delete:lead_document": "Delete Documents in Leads",
    "read:lead_notes": "View Notes' Lead Tab",
    "create:lead_notes": "Create Notes in Leads",
    "update:lead_notes": "Edit Notes in Leads",
    "delete:lead_notes": "Delete Notes on Leads",
    "read:lead_conversation": "View Messages in Leads",
    "create:lead_conversation": "Send Messages in Leads",
    "create:client": "Create New Clients",
    "delete:client": "Delete Clients",
    "create:client_import": "Import Clients",
    "update:client_flag": "Red Flag Clients",
    "update:client_unflag": "Remove flagged status in Clients",
    "update:client_agent": "Edit Agent's Client",
    "update:client_loan_officer": "Update Client's Loan Officer",
    "read:client_overall": "View Overall's Client Tab",
    "update:client_overall": "Edit Overall's Client Tab",
    "create:client_credit_scores": "Create New Credit Score in Client",
    "update:client_credit_scores": "Edit Credit Scores in Client",
    "delete:client_credit_scores": "Delete Credit Scores in Client",
    "read:client_program": "View Programs' Client Tab",
    "create:client_program": "Create Programs in Clients Tab",
    "update:client_program": "Edit Client Programs",
    "delete:client_program": "Delete Clients' Programs ",
    "read:client_payment": "View Payment's Client Tab",
    "read:client_financial": "View Financial Disclousure's Client Tab",
    "update:client_financial": "Edit Financial Disclousure's Client Tab",
    "read:client_document": "View Documents' Client Tab",
    "import:client_document": "Upload Documents in Clients",
    "delete:client_document": "Delete Documents in Clients",
    "read:client_notes": "View Notes' Client Tab",
    "create:client_notes": "Create Notes in Clients",
    "update:client_notes": "Edit Notes in Clients",
    "delete:client_notes": "Delete Notes on Clients",
    "read:client_conversation": "View Messages in Clients",
    "create:client_conversation": "Send Messages in Clients",
    "create:employee": "Create New Employees",
    "delete:employee": "Delete Employees",
    "read:employee_overall": "View Overall's Employee Tab",
    "update:employee_overall": "Edit Overall's Employee Tab",
    "read:employee_contact": "View Contact's Employee Tab",
    "create:employee_notes": "Create Notes in Employees",
    "update:employee_notes": "Edit Notes in Employees",
    "import:employee_document": "Upload Documents in Employee",
    "delete:employee_notes": "Delete Notes in Employees",
    "delete:employee_document": "Delete Documents in Employee",
    "read:employee_commissions": "View Commissions' Employee Tab",
    "update:employee_commissions": "Edit Commissions' Employee Tab",
    "create:campaign_bulk_messages": "Create Bulk Messages",
    "read:campaign_bulk_messages": "View Bulk Messages",
    "read:campaign": "View Campaigns",
    "create:campaign": "Create Campaigns",
    "update:campaign": "Edit Campaigns",
    "delete:campaign": "Delete Campaigns",
    "read:campaign_templates": "View Campaign Templates",
    "update:campaign_templates": "Edit Campaign Templates",
    "create:campaign_templates": "Create Campaign Templates",
    "delete:campaign_templates": "Delete Campaign Templates",
    "create:user": "Create New Users",
    "read:user": "View Users",
    "update:user": "Edit Users",
    "delete:user": "Delete Users",
    "update:user_role_permissions": "Edit Users' Roles and Permissions",
    "update:user_password": "Edit Users' Passwords",
    "create:calendar_appointments": "Create Appointments",
    "update:calendar_appointments": "Edit Appointments",
    "delete:calendar_appointments": "Delete Appointments",
    "create:calendar_appointments_superior": "Assign Appointments to Superior Roles",
    "create:calendar_appointments_inferior": "Assign Appointments to Inferior Roles",
    "read:calendar_appointments_assigned_by": "View Appointments Assigned by",
    "create:calendar_tasks": "Create Tasks",
    "update:calendar_tasks": "Edit Tasks",
    "delete:calendar_tasks": "Delete Tasks",
    "create:calendar_tasks_other": "Assign Tasks to Others",
    "create:calendar_tasks_superior": "Assign Tasks to Superior Roles",
    "create:calendar_tasks_inferior": "Assign Tasks to Inferior Roles",
    "read:calendar_tasks_assigned_by": "View Tasks Assigned by",
    "read:settings_dropdowns": "View Settings Dropdowns",
    "read:settings_round_robin": "View Settings Round Robin",
    "read:settings_creditors": "View Settings Creditors",
    "update:settings_dropdowns": "Edit Settings Dropdowns",
    "update:settings_round_robin": "Edit Settings Round Robin",
    "update:settings_creditors": "Edit Settings Creditors",
};
