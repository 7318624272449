import { Store } from "react-notifications-component";
import { datadogRum } from "@datadog/browser-rum";
export type NotificationMessagesType = {
    OK: NotificationType;
    KO: NotificationType;
};

export type NotificationType = {
    title: string;
    message: string;
    type: NotificationTypeType;
    time?: number;
};

export type NotificationTypeType = "success" | "danger" | "warning" | "info" | "default";

export const openNotification = (n: NotificationType) => {
    Store.addNotification({
        title: n.title,
        message: n.message,
        type: n.type,
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: n.time || 4000,
            onScreen: true,
        },
    });
};

const successType: NotificationTypeType = "success";
const dangerType: NotificationTypeType = "danger";
const infoType: NotificationTypeType = "info";
const warningType: NotificationTypeType = "warning";

export const notificationApiError = (message: string) => {
    //Currently we are not using message but can be used in future
    openNotification({
        title: "Error",
        message: "Failed to fetch data",
        type: dangerType,
    });
    datadogRum.addError("Failed to fetch data", {});
};
export const notificationToShow = (error: any, msg: any) => {
    if (error) {
        openNotification(msg["KO"]); // Show fail notification
    } else {
        openNotification(msg["OK"]); // show success notification
    }
};

export const notifyIntergerError = (errorMessage: string) => {
    return {
        title: "Input Error",
        message: `${errorMessage} is not a valid number`,
        type: dangerType,
    };
};

export const notifyIntergerTooBigError = (errorMessage: string) => {
    return {
        title: "Input Error",
        message: `${errorMessage} number is too big`,
        type: dangerType,
    };
};

export const NOTIFY_TEXT_LENGTH_ERROR = {
    title: "Input Error",
    message: `text is too long`,
    type: dangerType,
};

export const NOTIFY_FORBIDDEN_ERROR = {
    title: "Insufficient Permission",
    message: `You do not have the right permission for this operation`,
    type: warningType,
};

export const NOTIFY_REPORT_GENERATED = {
    OK: {
        title: "Done!",
        message: "Your report has been generated successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_VIEW_GENERATED = {
    OK: {
        title: "Done!",
        message: "Your file view has been generated successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_DATE_ERROR = {
    title: "Input Error",
    message: "Date is not valid",
    type: dangerType,
};

export const NOTIFY_NETWORK_ERROR = {
    title: "Error",
    message: "This error will be reported. Try again later.",
    type: dangerType,
};

export const NOTIFY_TASK_CREATED = {
    OK: {
        title: "Saved!",
        message: "The task has been created successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The task has not been modified.",
        type: infoType,
    },
};


export const NOTIFY_TASK_COMPLETED = {
    OK: {
        title: "Saved!",
        message: "The task has been marked as completed.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The task has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_TASK_DELETED = {
    OK: {
        title: "Saved!",
        message: "The task has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The task has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_APPOINTMENT_CREATED = {
    OK: {
        title: "Saved!",
        message: "The appointment has been created successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The appointment has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_APPOINTMENT_DELETED = {
    OK: {
        title: "Saved!",
        message: "The appointment has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The appointment has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_LEAD_SAVED = {
    OK: {
        title: "Saved!",
        message: "The Lead has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};

export const EMAIL = {
    OK: {
        title: "Success!",
        message: "Email has been sent to the selected contacts.",
        type: successType,
    },
    KO: {
        title: "Error!",
        message: "Email has not been sent to the selected contacts.",
        type: dangerType,
    },
};
export const SMS = {
    OK: {
        title: "Success!",
        message: "Text Message has been sent to the selected contacts.",
        type: successType,
    },
    KO: {
        title: "Error!",
        message: "Text Message has not been sent to the selected contacts.",
        type: dangerType,
    },
};

export const NOTIFY_LEAD_DELETED = {
    OK: {
        title: "Deleted!",
        message: "The Lead has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};


export const NOTIFY_LEAD_CONVERTED = {
    OK: {
        title: "Deleted!",
        message: "The Lead has been converted to a client successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Lead has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_USER_DELETED = {
    OK: {
        title: "Deleted!",
        message: "The user has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The user has not been modified.",
        type: infoType,
    },
};
export const NOTIFY_TEMPLATE_DELETED = {
    OK: {
        title: "Deleted!",
        message: "The template has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The template has not been modified.",
        type: infoType,
    },
};

export const NOTIFY_CAMPAIGN_DELETED = {
    OK: {
        title: "Deleted!",
        message: "The campaign has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The campaign has not been modified.",
        type: infoType,
    },
};


export const NOTIFY_USER_UNAUTHORIZED = {
    KO: {
        title: "Unauthorized",
        message: "You can't delete admin or super admin.",
        type: dangerType,
    },
};

export const NOTIFY_CLIENT_SAVED = {
    OK: {
        title: "Saved!",
        message: "Report successfully saved.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The report was not saved.",
        type: infoType,
    },
};

export const NOTIFY_CLIENT_DELETED = {
    OK: {
        title: "Saved!",
        message: "The Client has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_NOT_FOUND = {
    title: "Not Found!",
    message: "The information you're looking for does not exist in the database.",
    type: warningType,
};

export const NOTIFY_PROFILE_SAVED = {
    OK: {
        title: "Saved!",
        message: "Your profile details have been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_PROFILE_IMAGE_FAIL = {
    title: "Error",
    message: "Failed to save profile image. This incident has been notified to Devio.",
    type: dangerType,
};

export const NOTIFY_FLAG_SAVED = {
    OK: {
        title: "Saved!",
        message: "The users status has been updated successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_CAMPAIGN_DISABLED = {
    OK: {
        title: "Saved!",
        message: "The campaign has been disabled successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_DOCUMENT_SAVED = {
    OK: {
        title: "Saved!",
        message: "The Document has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_DOCUMENT_DELETED = {
    OK: {
        title: "Saved!",
        message: "The Document has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_USER_SAVED = {
    OK: {
        title: "Saved!",
        message: "User details has been updated successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The user was not saved.",
        type: infoType,
    },
};
export const NOTIFY_USER_PASSWORD_SAVED = {
    OK: {
        title: "Saved!",
        message: "User password has been updated successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    }
};
export const NOTIFY_NOTIFICATION = {
    AS_READ: {
        title: "Saved!",
        message: "Notification marked as read correctly",
        type: successType,
    },
    AS_UNREAD: {
        title: "Saved!",
        message: "Notification marked as unread correctly",
        type: successType,
    },
    ALL: {
        title: "Saved!",
        message: "All notifications marked as read correctly",
        type: successType,
    },
};
export const NOTIFY_DOCUMENT_PINNED = {
    OK: {
        title: "Saved!",
        message: "Document pinned correctly",
        type: successType,
    },
};
export const NOTIFY_NOTE_PINNED = {
    OK: {
        title: "Saved!",
        message: "Note pinned correctly",
        type: successType,
    },
};
export const NOTIFY_TEMPLATE_SAVED = {
    OK: {
        title: "Saved!",
        message: "Template has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The template was not saved.",
        type: infoType,
    },
};
export const NOTIFY_CAMPAIGN_SAVED = {
    OK: {
        title: "Saved!",
        message: "Campaign has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    CANCEL: {
        title: "Canceled",
        message: "The Campaign was not saved.",
        type: infoType,
    },
};

export const NOTIFY_CONTACT_CREATED = {
    OK: {
        title: "Created!",
        message: "This contact has been created successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
    AE: {
        title: "Error",
        message: "This operation went wrong. Contact already exists.",
        type: dangerType,
    },
};

export const NOTIFY_NOTE_SAVED = {
    OK: {
        title: "Saved!",
        message: "Your note has been saved successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_NOTE_DELETED = {
    OK: {
        title: "Saved!",
        message: "Your note has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_GENERIC_ERROR = {
    title: "Error",
    message: "This operation went wrong. This incident has been notified to Devio.",
    type: dangerType,
};


export const NOTIFY_CREDIT_SCORES_DELETED = {
    OK: {
        title: "Saved!",
        message: "The credit scores have been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_HOME_VIEWED_DELETED = {
    OK: {
        title: "Saved!",
        message: "The viewed home have been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_TRADELINE_DELETED = {
    OK: {
        title: "Saved!",
        message: "The tradeline have been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_PROGRAM_DELETED = {
    OK: {
        title: "Saved!",
        message: "The program has been deleted successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};

export const NOTIFY_PROGRAM_CREATED = {
    OK: {
        title: "Saved!",
        message: "The program has been created successfully.",
        type: successType,
    },
    KO: {
        title: "Error",
        message: "This operation went wrong. This incident has been notified to Devio.",
        type: dangerType,
    },
};