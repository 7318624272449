import React, { createElement, Fragment, useEffect, useRef } from "react";
import { render } from "react-dom";
import { autocomplete } from "@algolia/autocomplete-js";
import { FeedBoxActivity, FeedBoxNotes } from "../feedboxes";
import { Auth0Provider } from "@auth0/auth0-react";
import { PersonAlgolia } from "./Autocomplete";
import PersonInfo from "../personInfo/PersonInfo";
import { RecoilRoot } from "recoil";
import { buildUrl, ROUTES } from "../../config";

export function AutocompleteContainer(props: any) {
    const containerRef = useRef(null);

    useEffect(() => {
        if (!containerRef.current) {
            return undefined;
        }

        const search = autocomplete<PersonAlgolia>({
            container: containerRef.current,
            components: {
                FeedBoxActivity,
                FeedBoxNotes,
                PersonInfo,
                Auth0Provider,
                RecoilRoot,
            },
            renderer: { createElement, Fragment },
            render(params, root) {
                if (params.sections.length == 0) {
                    render(<span />, root);
                    return;
                }

                const preview = params.state.context.preview as PersonAlgolia;
                const { components } = params;
                render(
                    <components.RecoilRoot>
                        <components.Auth0Provider
                            domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
                            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
                            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                            redirectUri={process.env.REACT_APP_URL || "http://localhost:3000"}
                            useRefreshTokens={true}
                        >
                            <Fragment>
                                <div className="aa-Grid bg-color3">
                                    <div className="aa-Results aa-Column bg-color8">{params.children}</div>
                                    {preview &&
                                        <div className="aa-Preview aa-Column  overflow-y-auto">
                                            <div
                                                className="side flex flex-col content-start items-center sm:flex-row sm:flex-wrap lg:max-w-[290px] xl:max-w-[300px] gap-6">
                                                <components.PersonInfo
                                                    person_id={preview.objectID}
                                                    first_name={preview.first_name}
                                                    last_name={preview.last_name}
                                                    email={preview.email}
                                                    phone={preview.phone}
                                                    flagFor={preview.type === "credit_lead" ? "lead" : "client"}
                                                    autocomplete
                                                />
                                                <components.FeedBoxActivity
                                                    href={preview.objectID && buildUrl(ROUTES.ACTIVITY, {}, "", { id: preview.objectID })}
                                                    id={preview.objectID}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Fragment>
                        </components.Auth0Provider>
                    </components.RecoilRoot>
                    , root);
            },
            ...props,
        });

        return () => {
            search.destroy();
        };
    }, [props]);


    return <div ref={containerRef} className="flex-grow mx-1" />;
}