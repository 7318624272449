/**
 * This custom hook is used for dealing with errors. Its mean to helps
 * us centrialize they way we handle errors in the frontend.
 *
 * The hooks has no input but returns a function that can be call
 * when a errors needs to be dealt with.
 */

/**
 * Libraries import
 */
import { useAuth0 } from "@auth0/auth0-react";
import { useSetRecoilState } from "recoil";
/**
 * Import recoil states
 */
import { recAlreadyExistPopup } from "../config/recoil/atoms";
/**
 * Import notification messages
 */
import {
    notifyIntergerError,
    notifyIntergerTooBigError,
    NOTIFY_DATE_ERROR,
    NOTIFY_FORBIDDEN_ERROR,
    NOTIFY_GENERIC_ERROR,
    NOTIFY_TEXT_LENGTH_ERROR,
    openNotification,
} from "../utils";
/**
 * Common sequelize error messages
 */
const SEQUELIZE_ERROR_TYPE = {
    INTEGER: "invalid input syntax for type integer",
    DATE: "invalid input syntax for type date",
    INTEGER_TOO_BIG: "is out of range for type integer",
    CHARACTERS_TOO_LONG: "value too long for type character",
};
/**
 * Http response status codes
 */
const RESPONSE_STATUS_CODE = {
    FORBIDDEN: 403,
    CONFLICT: 409,
    UNAUTHORIZED: 401,
};

/**
 * Return type. Takes any as an input, since erros comes in many
 * different formats.
 *
 */
type iReturn = (e: any) => void;

/***********************Hook***********************/

export const useHandleError = (): iReturn => {
    /**
     * Import logout method from auth0. This is used to log a user out of the
     * application.
     */
    const { logout } = useAuth0();

    /**
     * Used to open the Email already exists popup.
     */
    const setAlreadyExistPopup = useSetRecoilState(recAlreadyExistPopup);

    const handleError = (e: any) => {
        /**
         * Keep the console.error for dev, this can be commented out in production
         */
        console.error(e);

        /**
         * Check for status code of 401. This status is returned from the server when
         * a users token has expired/deleted. We log the user out instantly.
         */

        // if (e?.message === "Failed to fetch") {
        //     console.log("Here: ", e?.message, e?.code, e?.status);
        // }
        if (e?.code === RESPONSE_STATUS_CODE.UNAUTHORIZED) {
            logout();
            return;
        }
        /**
         * Check for status code of 403. This status is returned from the server when
         * a resource is forbidden. Eg. A real estate trying to delete  a super admin or
         * when a user does not have permissions to access certain information.
         */
        if (e?.code === RESPONSE_STATUS_CODE.FORBIDDEN) {
            openNotification(NOTIFY_FORBIDDEN_ERROR);
            return;
        }
        /**
         * Check for status code of 409. This status is returned from server when
         * an email address exists in the database. Email address must be unique.
         * The user will be shown a popup asking if the user would like to see which
         * resource is the email address attached too.
         */
        if (e?.code === RESPONSE_STATUS_CODE.CONFLICT) {
            console.log("error conflict");
            setAlreadyExistPopup({ check: e.check, id: e.id, showPopup: true });
            return;
        }

        /**
         * The following are used to process common sequelize type errors.
         */
        if (typeof e?.error === "string") {
            /**
             * Split the error message. Sequelize returns its error
             * msgs in this format > invalid input syntax for type date: for some input
             *
             */
            const [msg, val] = e.error.split(":");

            if (msg.search(SEQUELIZE_ERROR_TYPE.INTEGER) !== -1) {
                openNotification(notifyIntergerError(val));
                return;
            }

            if (msg.search(SEQUELIZE_ERROR_TYPE.DATE) !== -1) {
                openNotification(NOTIFY_DATE_ERROR);
                return;
            }

            if (msg.search(SEQUELIZE_ERROR_TYPE.CHARACTERS_TOO_LONG) !== -1) {
                openNotification(NOTIFY_TEXT_LENGTH_ERROR);
                return;
            }

            if (msg.search(SEQUELIZE_ERROR_TYPE.INTEGER_TOO_BIG) !== -1) {
                openNotification(notifyIntergerTooBigError(val));
                return;
            }
        }
        // console.error(e);
        /**
         * If the error is none of the above show a generic error msg.
         */
        return openNotification(NOTIFY_GENERIC_ERROR);
    };

    return handleError;
};
