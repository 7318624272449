import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const CheckAuth = ({ children }: { children: any }) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const getToken = (options: any) => {
    getAccessTokenSilently(options)
      .then(() => {
      })
      .catch((error: any) => {
        console.log(error, 'logout1');
        logout();
      });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: process.env.REACT_APP_URL || '',
        },
      });
      return;
    }

    getToken(process.env.REACT_APP_AUTH0_AUDIENCE);
  }, [isLoading, isAuthenticated]);

  return children;
};

export default CheckAuth;
