/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import LoaderWrapper from "../components/loaders/loader";
import Navbar from "../components/navigation/navbar/Navbar";
import Sidebar from "../components/navigation/sidebar/Sidebar";
import { API, API_BASEURL, PERMISSIONS, ROUTES, SWR_CONFIG, buildUrl } from "../config";
import {
    loadingAuth,
    userInfo,
    userPermissions,
    userRoles,
} from "../config/recoil/atoms";
import { usePermissions } from "../hooks";
import useGetSWR from "../hooks/useGetSWR";
import {
    ActivityLogo,
    CalendarLogo,
    CampaignsLogo,
    ClientsLogo,
    DashboardLogo,
    LeadsLogo,
    SettingsIcon,
    UsersLogo,
} from "../icons";
import { ApplicationRoutes } from "../routes";
import { IsingleUserRes } from "../types";

export interface MenuItem {
    name: string;
    tooltip: string;
    url: string;
    active: boolean;
    icon: any;
    notification?: number;
}

function MainScreen() {
    /**
     * Permissions functions
     */
    const { checkPermission } = usePermissions();
    const info = useRecoilValue(userInfo);
    const { user } = useAuth0();
    const setUserInfo = useSetRecoilState(userInfo);
    const setUserRoles = useSetRecoilState(userRoles);
    const setLoadingAuth = useSetRecoilState(loadingAuth);
    const [userPermissionsValue, setUserPermissions] = useRecoilState(userPermissions);
    const loading = useRecoilValue(loadingAuth);

    const { logout } = useAuth0();
    const url = buildUrl(API.PROFILE, { id: user?.sub || "0" }, API_BASEURL); // build url

    const { data: userRes, error } = useGetSWR<IsingleUserRes>(
        user?.sub ? url : null,
        SWR_CONFIG.FORMS
    ); // get form data

    /**
     * Generic User Menu Links
     */
    const [menu, setMenu] = useState<MenuItem[]>([
        {
            name: "Dashboard",
            tooltip: "Dashboard",
            url: "/dashboard",
            active: true,
            icon: DashboardLogo,
            notification: 0,
        },
    ]);

    useEffect(() => {
        if (error && (error as any).status === 401) {
            // logout({ returnTo: process.env.REACT_APP_URL });
            console.log(error);
        }
    }, [error]);

    useEffect(() => {
        if (userRes?.permissions) {
            // Set user permissions
            setUserPermissions(userRes.permissions);
        }
        if (userRes !== undefined) {
            if (userRes.user && userRes.code === 200 && userRes.success) {
                setLoadingAuth(false);
                /**
                 * Set user info
                 */
                setUserInfo({
                    name: userRes?.user?.name || "",
                    profile_image: userRes?.user?.profile_image || info.profile_image,
                    person_id: userRes?.user?.person_id || "",
                });
                if (userRes.user.role?.name) setUserRoles([userRes.user.role.name as never])
                /**
                 * Set user on datadog session
                 */
                datadogRum.setUser({
                    id: userRes?.user?.person_id || "",
                    email: userRes?.user?.email || "",
                    name: userRes?.user?.name || "",
                });
            } else {
                // If any error in response, logout
                console.log(userRes, 'logout2');
                logout();
            }
        }
    }, [userRes]);

    useEffect(() => {
        /**
         * Set Menu according to user's permissions
         */
        if (userPermissionsValue.length > 0) {
            setMenu(getMenu());
        }
    }, [userPermissionsValue]);

    const getMenu = () => {
        const menu = [];
        menu.push({
            name: "Dashboard",
            tooltip: "Dashboard",
            url: "/dashboard",
            active: true,
            icon: DashboardLogo,
            notification: 0,
        });
        if (checkPermission(PERMISSIONS.LEAD_ACCESS)) {
            menu.push({
                name: "Leads",
                tooltip: "Leads",
                url: ROUTES.LEADS,
                active: true,
                icon: LeadsLogo,
            });
        }
        if (checkPermission(PERMISSIONS.CLIENT_ACCESS)) {
            menu.push({
                name: "Clients",
                tooltip: "Clients",
                url: ROUTES.CLIENTS,
                active: true,
                icon: ClientsLogo,
            });
        }
        if (checkPermission(PERMISSIONS.ACTIVITY_ACCESS)) {
            menu.push({
                name: "Activity",
                tooltip: "Activity",
                url: "/activities",
                active: true,
                icon: ActivityLogo,
            });
        }
        if (checkPermission(PERMISSIONS.CAMPAIGN_ACCESS)) {
            menu.push({
                name: "Campaigns",
                tooltip: "Campaigns",
                url: "/campaigns",
                active: true,
                icon: CampaignsLogo,
            });
        }
        if (checkPermission(PERMISSIONS.USER_ACCESS)) {
            menu.push({
                name: "Users",
                tooltip: "Users",
                url: "/users",
                active: true,
                icon: UsersLogo,
            });
        }
        if (checkPermission(PERMISSIONS.CALENDAR_ACCESS)) {
            menu.push({
                name: "Calendar",
                tooltip: "Calendar",
                url: "/calendar",
                active: true,
                icon: CalendarLogo,
            });
        }
        if (checkPermission(PERMISSIONS.SETTINGS_ACCESS)) {
            menu.push({
                name: "Settings",
                tooltip: "Settings",
                url: "/settings",
                active: true,
                icon: SettingsIcon,
            });
        }
        return menu;
    };

    /**
     * Show a loader while we wait for api response
     */
    if (loading) {
        return <LoaderWrapper />;
    }

    return (
        <div className="flex flex-col md:flex-row relative">
            <Sidebar menu={menu} />
            <div
                className="flex flex-col flex-1
      min-h-screen bg-color5 pt-8 min-w-0
      md:pt-0 pb-12 relative w-full"
            >
                <Navbar hide={false} />
                {/* <Unauthorized /> */}
                <div className="">
                    <div className="crm_container max-w-none">
                        {/* All the application routes are in this component */}
                        <ApplicationRoutes />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainScreen;
