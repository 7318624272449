import { useRecoilValue } from "recoil";
import { PERMISSIONS, ROLES } from "../config";
import { userPermissions, userRoles } from "../config/recoil/atoms";

export const usePermissions = () => {
    const userAuthority = useRecoilValue(userRoles) as string[];
    const permissions = useRecoilValue(userPermissions);

    const hasUserAuthority = () => {
        return userAuthority.length > 0;
    };

    const checkPermission = (permission: PERMISSIONS) => {
        return permissions.includes(permission.valueOf());
    };

    const checkRole = (role: ROLES) => {
        return userAuthority.includes(role.valueOf());
    };

    const checkAssignableContact = () => checkRole(ROLES.RE_AGENT) || checkRole(ROLES.LOAN_OFFICER);

    return {
        checkAssignableContact,
        hasUserAuthority,
        checkPermission,
        checkRole,
    };
};
