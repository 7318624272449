import { compile } from "path-to-regexp";

// If API call names does not start with PUT, POST, or DELETE, it
// means it is a GET request.

export const API = {
    ALL_LEADS: "/lead/all",
    COMPLETE_ONBOARDING: "/lead/complete-onboard/:id",
    CREATE_LEAD: "/lead/new",
    SINGLE_LEAD: "/lead/:id",
    DELETE_LEAD: "/lead/delete/:id",
    SINGLE_LEAD_OVERALL: "/lead/lead-overall/:id",
    PUT_SINGLE_LEAD: "/lead/update",
    UPDATE_DOCUMENT_PIN: "/documents/pin/:id",
    UPDATE_DOCUMENT_UNPIN: "/documents/unpin/:id",
    UPDATE_DOCUMENT_NAME: "/documents/update-name",
    LEAD_DOCUMENT_UPLOAD: "/lead/document/new",
    LEAD_DOCUMENT_VIEW: "/lead/document/view",
    LEAD_DOCUMENT_DELETE: "/lead/document/delete",
    LEAD_DOCUMENT_DOWNLOAD: "/lead/document/download",
    LEAD_DOCUMENT_ALL: "/lead/document/all", // requires query param of id,
    LEAD_NEW_7_DAYS: "/lead/new/7days",
    LEAD_UNCONTACTED: "/lead/new/uncontacted",
    PUT_LEAD_CONVERT_CLIENT: "/lead/client-conversion/:id",
    LEAD_FINANCIAL_DISCLOSURE: "/lead/financial-disclosure/:id",
    PUT_LEAD_FINANCIAL_DISCLOSURE: "/lead/financial-disclosure/update",
    CLIENT_DOCUMENT_UPLOAD: "/client/document/new",
    CLIENT_DOCUMENT_DELETE: "/client/document/delete",
    CLIENT_DOCUMENT_VIEW: "/client/document/view",
    CLIENT_DOCUMENT_DOWNLOAD: "/client/document/download",
    CLIENT_DOCUMENT_ALL: "/client/document/all", // requires query param of id,
    CLIENT_FINANCIAL_DISCLOSURE: "/client/financial-disclosure/:id",
    CLIENT_NEW_7_DAYS: "/client/new/7days",
    PUT_CLIENT_FINANCIAL_DISCLOSURE: "/client/financial-disclosure/update",
    CLIENT_PROGRAM: "/client/program/:id",
    SINGLE_CLIENT_PROGRAM: "/client/single-program/:id",
    PUT_CLIENT_PROGRAM: "/client/program/update",
    POST_CLIENT_PROGRAM: "/client/program/create",
    LEAD_PROGRAM: "/lead/program/:id",
    POST_LEAD_PROGRAM: "/lead/program/create",
    DELETE_TRADELINE: "/client/tradeline/delete",
    DELETE_HOME_VIEWED: "/client/home-viewed/delete",
    ALL_CLIENTS: "/client/all",
    CREATE_CLIENT: "/client/new",
    SINGLE_CLIENT: "/client/:id",
    PUT_SINGLE_CLIENT: "/client/update",
    ALL_USERS: "/user/all",
    USERS_ROUND_ROBIN: "/user/round-robin",
    PUT_ROUND_ROBIN: "/user/update/round-robin",
    CREATE_USER: "/user/new",
    SINGLE_USER: "/user/:id",
    PUT_SINGLE_USER: "/user/update",
    PUT_USER_DISABLED: "/user/disabled",
    PUT_USER_REASSIGN: "/user/reassign",
    PUT_SINGLE_USER_PROFILE: "/user/profile",
    POST_IMAGE_PROFILE: "/user/profile-image",
    POST_NEW_USER: "/user/new",
    POST_PASSWORD_UPDATE: "/user/password",
    PROFILE: "/user/auth0/:id",
    USER_SIMPLE_ALL: "/user/simplified/all",
    USER_SIMPLE_ALL_DROPDOWN: "/user/dropdown/all",
    USER_SIMPLE_CALENDAR: "/user/dropdown/calendar",
    DELETE_USER: "/user/delete/:id",
    DELETE_CLIENT: "/client/delete/:id",
    DELETE_PROGRAM: "/client/program/delete",
    DELETE_CREDIT_SCORES: "/client/credit-scores/delete",
    DELETE_LEAD_CREDIT_SCORES: "/lead/credit-scores/delete",
    ALL_LANGUAGES: "/language/all",
    ALL_ROLES: "/role/all",
    ALL_NOTES: "/note/all", // requires query param of id,
    POST_NOTE_NEW: "/note/new",
    LEAD_FLAG: "/lead/red-flag/:id",
    PUT_LEAD_FLAG: "/lead/red-flag/update",
    CLIENT_FLAG: "/client/red-flag/:id",
    PUT_CLIENT_FLAG: "/client/red-flag/update",
    ALL_ACTIVITIES: "/activity/all",
    SINGLE_CONTACT_ACTIVITIES: "/activity/single-contact",
    SINGLE_ACTIVITY: "/activity/:id",
    ALL_CONTACTS: "/contact/all",
    SINGLE_CONTACT: "/contact/:id",
    ALL_CONVERSATIONS: "/conversation/all",
    LAST_SENDERS_CONVERSATIONS: "/conversation/last-senders/names",
    SINGLE_CONVERSATIONS: "/conversation/:id",
    SIGNED_URL: "/webhook/image_signed_url",
    CONTACT_NAMES: "/contact/names",
    SEND_EMAIL: "/message/email/send",
    SEND_SMS: "/message/sms/send",
    DELETE_CONVERSATIONS: "/conversation/delete/:id",
    USER_TASK_GET: "/user/task/:id",
    USER_TASK_CREATE: "/user/task/new",
    USER_TASK_DELETE: "/user/task/delete",
    USER_TASK_UPDATE: "/user/task/update",
    USER_TASKS_SINGLE: "/user/task/month/single",
    USER_TASK_ALL: "/user/task/all/:month",
    USER_APPOINTMENT_GET: "/appointment/:id",
    USER_APPOINTMENT_CREATE: "/appointment/new",
    USER_APPOINTMENT_DELETE: "/appointment/delete",
    USER_APPOINTMENT_UPDATE: "/appointment/update",
    USER_APPOINTMENT_SINGLE: "/appointment/month/single",
    USER_APPOINTMENT_ALL: "/appointment/month/all/:month",
    CONTACT_SEARCH_ALL: "/contact/all/names",
    TEMPLATE_ALL: "/template/all",
    CREATE_TEMPLATE: "/template/new",
    SINGLE_TEMPLATE: "/template/:id",
    PUT_SINGLE_TEMPLATE: "/template/update",
    SINGLE_TEMPLATE_DELETE: "/template/delete",
    CAMPAIGN_ALL: "/campaign/all",
    SINGLE_CAMPAIGN: "/campaign/:id",
    SINGLE_CAMPAIGN_HISTORY: "/campaign/history/:id",
    CAMPAIGN_CREATE: "/campaign/new",
    PUT_SINGLE_CAMPAIGN: "/campaign/update",
    SINGLE_CAMPAIGN_DELETE: "/campaign/delete",
    PUT_CAMPAIGN_DISABLED: "/campaign/disabled",
    SEND_SMS_FILTER: "/campaign/sms/send_filter",
    SEND_EMAIL_FILTER: "/campaign/email/send_filter",
    SEND_SMS_BULK: "/campaign/sms/send",
    SEND_EMAIL_BULK: "/campaign/email/send",
    BULK_MESSAGE_FILTERS: "/campaign/bulk_filters",
    BULK_CONVERSATION_ALL: "/campaign/bulk/all",
    SINGLE_BULK_CONVERSATION: "/campaign/bulk/:id",
    // GET_LOCATION: "https://cors-anywhere.herokuapp.com/https://api.geoapify.com/v1/geocode/search",
    GET_LOCATION: "/geoapify/:postcode",
    CITY_DROPDOWN_SEARCH: "/geoapify/city",
    SETTING_ALL: "/dynamic_list/all",
    SINGLE_SETTING: "/dynamic_list/:id",
    BY_NAME_SETTING: "/dynamic_list/name",
    NEW_DYNAMIC_LIST_VALUE: "/dynamic_list/new",
    SINGLE_SETTING_DELETE: "/dynamic_list/delete",
    PUT_DYNAMIC_LIST: "/dynamic_list/update",
    NOTIFICATIONS: "/notification/all",
    NOTIFICATIONS_UNREAD: "/notification/unread",
    PUT_UNREAD_NOTIFICATION: "/notification/mark-unread/:id",
    PUT_READ_NOTIFICATION: "/notification/mark-read/:id",
    PUT_READ_ALL_NOTIFICATION: "/notification/mark-read/all",
    POST_PIN_NOTE: "/note/pin/:id",
    POST_UNPIN_NOTE: "/note/unpin/:id",
    UPDATE_NOTE: "/note/update",
    DELETE_NOTE: "/note/delete",
    GET_FILE: "/public/file/:id",
};

/**
 *
 * @param apiUrl in pathToRegexp format
 * @param params key value pairs to build url
 * @param baseUrl the  baseurl eg. http://localhost:3000.
 * @param query query params in object format eg. {id : 1234}
 * @returns a complete url.
 */
export const buildUrl = (apiUrl: string, params = {}, baseUrl = "", query = {}) => {
    // @ts-ignore
    return `${baseUrl}${compile(apiUrl, { encode: encodeURIComponent })(params)}${
        Object.keys(query).length ? "?" : ""
    }${new URLSearchParams(query).toString()}`;
};
