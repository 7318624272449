import React from "react";
import algoliasearch from "algoliasearch";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import { createNullCache } from "@algolia/cache-common";
import { AutocompleteContainer } from "./AutocompleteContainer";
import { buildUrl, PERMISSIONS, ROUTES } from "../../config";
import { useHistory } from "react-router";
import { BaseItem } from "@algolia/autocomplete-core";
import { AutocompletePersonItem } from "./AutocompletePersonItem";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { clientsSearchOptions, leadsSearchOptions, userInfo } from "../../config/recoil/atoms";
import { usePermissions } from "../../hooks";
import { GetSources } from "@algolia/autocomplete-js";

const appId = process.env.REACT_APP_ALGOLIA_APP_ID || "";
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY || "";
const index = process.env.REACT_APP_ALGOLIA_INDEX || "";

// Do not cache search results. So deletes and converting lead to client
// are reflected instanly.
const searchClient = algoliasearch(appId, apiKey, { responsesCache: createNullCache() });
const HITS_PER_PAGE = 8;

export function Autocomplete() {
    const history = useHistory();
    const setClientOptions = useSetRecoilState(clientsSearchOptions);
    const setLeadOptions = useSetRecoilState(leadsSearchOptions);
    const user = useRecoilValue(userInfo);
    const { checkPermission } = usePermissions();

    const getFacetFilters = () => {
        if (checkPermission(PERMISSIONS.CLIENT_READ) && checkPermission(PERMISSIONS.LEAD_READ)) return '';
        else if (checkPermission(PERMISSIONS.CLIENT_READ_OWN) && checkPermission(PERMISSIONS.LEAD_READ)) return [[`agent_id:${user.person_id}`, 'type:credit_lead']];
        if (checkPermission(PERMISSIONS.CLIENT_READ) && checkPermission(PERMISSIONS.LEAD_READ_OWN)) return [[`agent_id:${user.person_id}`, 'type:credit_client']];
        else if (checkPermission(PERMISSIONS.CLIENT_READ_OWN) && checkPermission(PERMISSIONS.LEAD_READ_OWN)) return [`agent_id:${user.person_id}`];
            return [[`agent_id:${user.person_id}`, 'type:credit_client'], ['type:credit_lead']];
    }

    const sources: GetSources<PersonAlgolia> = () => [
        {
            sourceId: "person",
            getItems({ query }: any) {
                if (!query || query == "") {
                    return [];
                }

                return getAlgoliaResults({
                    searchClient,
                    queries: [
                        {
                            indexName: index,
                            query,
                            params: {
                                facetFilters: getFacetFilters(),
                                hitsPerPage: HITS_PER_PAGE,
                            },
                        },
                    ],
                });
            },
            onActive({ item, setContext }: any) {
                setContext({ preview: item });
            },
            onSelect(params: any) {
                const path =
                    params.item.type === "credit_lead"
                        ? ROUTES.SINGLE_LEADS
                        : ROUTES.SINGLE_CLIENTS;
                setClientOptions(undefined);
                setLeadOptions(undefined);
                history.push(buildUrl(path, { id: params.item.objectID }));
            },
            templates: {
                item({ item, components }: any) {
                    return <AutocompletePersonItem personItem={item} components={components} />;
                },
            },
        },
    ];

    return (
        <AutocompleteContainer
            getSources={sources}
            placeholder={"Search here..."}
            openOnFocus={true}
            detachedMediaQuery={""}
        />
    );
}

export interface PersonAlgolia extends BaseItem {
    objectID: string;
    first_name?: string;
    last_name?: string;
    spouse_first_name?: string;
    spouse_last_name?: string;
    email?: string;
    phone?: string;
    address?: string;
    social_security_number?: string;
    created_at?: string;
    type?: string;
}
