export const PERMISSIONS_DEPENDENCIES = {
    "read:lead_tab": [],
    "read:lead": [],
    "read:lead_own": [],
    "create:lead": [],
    "update:lead": [],
    "delete:lead": [],
    "create:lead_import": [],
    "update:lead_flag": [],
    "update:lead_unflag": [],
    "update:lead_agent": [],
    "update:lead_agent_own": [],
    "update:lead_convert": [],
    "update:lead_loan_officer": [],
    "update:lead_loan_officer_own": [],
    "read:lead_overall": [],
    "update:lead_overall": ["read:lead_overall"],
    "create:lead_credit_scores": ["read:lead_overall"],
    "update:lead_credit_scores": ["read:lead_overall"],
    "delete:lead_credit_scores": ["read:lead_overall"],
    "read:lead_financial": [],
    "update:lead_financial": ["read:lead_financial"],
    "read:lead_document": [],
    "import:lead_document": ["read:lead_document"],
    "delete:lead_document": ["read:lead_document"],
    "read:lead_notes": [],
    "create:lead_notes": ["read:lead_notes"],
    "update:lead_notes": ["read:lead_notes"],
    "delete:lead_notes": ["read:lead_notes"],
    "read:lead_conversation": [],
    "create:lead_conversation": ["read:lead_conversation"],

    "read:client_tab": [],
    "read:client": [],
    "read:client_own": [],
    "create:client": [],
    "update:client": [],
    "delete:client": [],
    "create:client_import": [],
    "update:client_agent": [],
    "update:client_agent_own": [],
    "update:client_flag": [],
    "update:client_unflag": [],
    "update:client_loan_officer": [],
    "update:client_loan_officer_own": [],
    "read:client_overall": [],
    "update:client_overall": ["read:client_overall"],
    "create:client_credit_scores": ["read:client_overall"],
    "update:client_credit_scores": ["read:client_overall"],
    "delete:client_credit_scores": ["read:client_overall"],
    "read:client_program": [],
    "create:client_program": ["read:client_program"],
    "update:client_program": ["read:client_program"],
    "delete:client_program": ["read:client_program"],
    "read:client_payment": [],
    "read:client_financial": [],
    "update:client_financial": ["read:client_financial"],
    "read:client_document": [],
    "import:client_document": ["read:client_document"],
    "delete:client_document": ["read:client_document"],
    "read:client_notes": [],
    "create:client_notes": ["read:client_notes"],
    "delete:client_notes": ["read:client_notes"],
    "update:client_notes": ["read:client_notes"],
    "read:client_conversation": [],
    "create:client_conversation": ["read:client_conversation"],

    "read:employee_tab": [],
    "read:employee": [],
    "read:employee_not_own": [],
    "create:employee": [],
    "delete:employee": [],
    "read:employee_overall": [],
    "update:employee_overall": ["read:employee_overall"],
    "read:employee_contact": [],
    "create:employee_notes": ["read:employee_contact"],
    "update:employee_notes": ["read:employee_contact"],
    "delete:employee_notes": ["read:employee_contact"],
    "import:employee_document": ["read:employee_contact"],
    "delete:employee_document": ["read:employee_contact"],
    "read:employee_commissions": [],
    "update:employee_commissions": ["read:employee_commissions"],

    "read:activity_tab": [],

    "read:campaign_tab": [],
    "read:campaign_bulk_messages": [],
    "create:campaign_bulk_messages": ["read:campaign_bulk_messages"],
    "read:campaign": [],
    "create:campaign": ["read:campaign"],
    "delete:campaign": ["read:campaign"],
    "update:campaign": ["read:campaign"],
    "read:campaign_templates": [],
    "create:campaign_templates": ["read:campaign_templates"],
    "delete:campaign_templates": ["read:campaign_templates"],
    "update:campaign_templates": ["read:campaign_templates"],

    "read:user_tab": [],
    "read:user": [],
    "create:user": ["read:user"],
    "update:user": ["read:user"],
    "delete:user": ["read:user"],
    "update:user_role_permissions": ["read:user", "update:user"],
    "update:user_password": ["read:user", "update:user"],

    "read:calendar_tab": [],
    "read:calendar_appointments": [],
    "read:calendar_appointments_own": [],
    "create:calendar_appointments": [],
    "update:calendar_appointments": [],
    "delete:calendar_appointments": [],
    "create:calendar_appointments_superior": ["create:calendar_appointments"],
    "create:calendar_appointments_inferior": ["create:calendar_appointments"],
    "read:calendar_appointments_assigned_by": [],
    "read:calendar_tasks": [],
    "read:calendar_tasks_own": [],
    "create:calendar_tasks": [],
    "update:calendar_tasks": [],
    "delete:calendar_tasks": [],
    "create:calendar_tasks_other": [],
    "create:calendar_tasks_superior": ["create:calendar_tasks"],
    "create:calendar_tasks_inferior": ["create:calendar_tasks"],
    "read:calendar_tasks_assigned_by": [],

    "read:settings_tab": [],
    "read:settings_dropdowns": [],
    "read:settings_round_robin": [],
    "read:settings_creditors": [],
    "": [],
    "update:settings_dropdowns": ["read:settings_dropdowns"],
    "update:settings_round_robin": ["read:settings_round_robin"],
    "update:settings_creditors": ["read:settings_creditors"],
};

export const MULTI_OPTION_PERMISSIONS_DEPENDENCIES = {
    "read:lead_tab": [],
    "read:lead": [],
    "read:lead_own": [],
    "create:lead": ["read:lead", "read:lead_own"],
    "update:lead": ["read:lead", "read:lead_own"],
    "delete:lead": ["read:lead", "read:lead_own"],
    "create:lead_import": ["read:lead", "read:lead_own"],
    "update:lead_flag": ["read:lead", "read:lead_own"],
    "update:lead_unflag": ["read:lead", "read:lead_own"],
    "update:lead_loan_officer": ["read:lead", "read:lead_own"],
    "update:lead_loan_officer_own": ["read:lead", "read:lead_own"],
    "update:lead_agent": ["read:lead", "read:lead_own"],
    "update:lead_agent_own": ["read:lead", "read:lead_own"],
    "update:lead_convert": ["read:lead", "read:lead_own"],
    "read:lead_overall": [],
    "update:lead_overall": [],
    "create:lead_credit_scores": [],
    "update:lead_credit_scores": [],
    "delete:lead_credit_scores": [],
    "read:lead_financial": [],
    "update:lead_financial": [],
    "read:lead_document": [],
    "import:lead_document": [],
    "delete:lead_document": [],
    "read:lead_notes": [],
    "create:lead_notes": [],
    "update:lead_notes": [],
    "delete:lead_notes": [],
    "read:lead_conversation": [],
    "create:lead_conversation": [],

    "read:client_tab": [],
    "read:client": [],
    "read:client_own": [],
    "create:client": ["read:client", "read:client_own"],
    "update:client": ["read:client", "read:client_own"],
    "delete:client": ["read:client", "read:client_own"],
    "create:client_import": ["read:client", "read:client_own"],
    "update:client_loan_officer": ["read:client", "read:client_own"],
    "update:client_loan_officer_own": ["read:client", "read:client_own"],
    "update:client_agent": ["read:client", "read:client_own"],
    "update:client_agent_own": ["read:client", "read:client_own"],
    "update:client_flag": ["read:client", "read:client_own"],
    "update:client_unflag": ["read:client", "read:client_own"],
    "read:client_overall": [],
    "update:client_overall": [],
    "create:client_credit_scores": [],
    "update:client_credit_scores": [],
    "delete:client_credit_scores": [],
    "read:client_program": [],
    "update:client_program": [],
    "create:client_program": [],
    "delete:client_program": [],
    "read:client_payment": [],
    "read:client_financial": [],
    "update:client_financial": [],
    "read:client_document": [],
    "import:client_document": [],
    "delete:client_document": [],
    "read:client_notes": [],
    "create:client_notes": [],
    "delete:client_notes": [],
    "update:client_notes": [],
    "read:client_conversation": [],
    "create:client_conversation": [],

    "read:employee_tab": [],
    "read:employee": [],
    "read:employee_not_own": [],
    "create:employee": ["read:employee", "read:employee_not_own"],
    "delete:employee": ["read:employee", "read:employee_not_own"],
    "read:employee_overall": ["read:employee", "read:employee_not_own"],
    "update:employee_overall": ["read:employee", "read:employee_not_own"],
    "read:employee_contact": ["read:employee", "read:employee_not_own"],
    "create:employee_notes": ["read:employee", "read:employee_not_own"],
    "update:employee_notes": ["read:employee", "read:employee_not_own"],
    "delete:employee_notes": ["read:employee", "read:employee_not_own"],
    "import:employee_document": ["read:employee", "read:employee_not_own"],
    "delete:employee_document": ["read:employee", "read:employee_not_own"],
    "read:employee_commissions": ["read:employee", "read:employee_not_own"],
    "update:employee_commissions": ["read:employee", "read:employee_not_own"],

    "read:activity_tab": [],

    "read:campaign_tab": [],
    "create:campaign_bulk_messages": [],
    "read:campaign_bulk_messages": [],
    "read:campaign": [],
    "create:campaign": [],
    "delete:campaign": [],
    "update:campaign": [],
    "read:campaign_templates": [],
    "create:campaign_templates": [],
    "delete:campaign_templates": [],
    "update:campaign_templates": [],

    "read:user_tab": [],
    "read:user": [],
    "create:user": [],
    "update:user": [],
    "delete:user": [],
    "update:user_role_permissions": [],
    "update:user_password": [],

    "read:calendar_tab": [],
    "read:calendar_appointments": [],
    "read:calendar_appointments_own": [],
    "create:calendar_appointments": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "update:calendar_appointments": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "delete:calendar_appointments": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],

    "create:calendar_appointments_superior": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "create:calendar_appointments_inferior": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "read:calendar_appointments_assigned_by": [
        "read:calendar_appointments",
        "read:calendar_appointments_own",
    ],
    "read:calendar_tasks": [],
    "read:calendar_tasks_own": [],
    "create:calendar_tasks": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "update:calendar_tasks": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "delete:calendar_tasks": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "create:calendar_tasks_other": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "create:calendar_tasks_superior": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "create:calendar_tasks_inferior": ["read:calendar_tasks", "read:calendar_tasks_own"],
    "read:calendar_tasks_assigned_by": ["read:calendar_tasks", "read:calendar_tasks_own"],

    "read:settings_tab": [],
    "read:settings_dropdowns": [],
    "read:settings_round_robin": [],
    "read:settings_creditors": [],
    "update:settings_dropdowns": [],
    "update:settings_round_robin": [],
    "update:settings_creditors": [],
};

export const REMOVE_PERMISSIONS_DEPENDENCIES = {
    "read:lead_tab": [],
    "read:lead": [
        "create:lead",
        "update:lead",
        "delete:lead",
        "create:lead_import",
        "update:lead_flag",
        "update:lead_unflag",
        "update:lead_loan_officer",
        "update:lead_loan_officer_own",
        "update:lead_agent",
        "update:lead_agent_own",
        "update:lead_convert",
    ],
    "read:lead_own": [
        "create:lead",
        "update:lead",
        "delete:lead",
        "create:lead_import",
        "update:lead_flag",
        "update:lead_unflag",
        "update:lead_loan_officer",
        "update:lead_loan_officer_own",
        "update:lead_agent",
        "update:lead_agent_own",
        "update:lead_convert",
    ],
    "create:lead": [],
    "update:lead": [],
    "delete:lead": [],
    "create:lead_import": [],
    "update:lead_flag": [],
    "update:lead_unflag": [],
    "update:lead_loan_officer": [],
    "update:lead_loan_officer_own": [],
    "update:lead_agent": [],
    "update:lead_agent_own": [],
    "update:lead_convert": [],
    "read:lead_overall": [
        "update:lead_overall",
        "create:lead_credit_scores",
        "update:lead_credit_scores",
        "delete:lead_credit_scores",
    ],
    "update:lead_overall": [],
    "create:lead_credit_scores": [],
    "update:lead_credit_scores": [],
    "delete:lead_credit_scores": [],
    "read:lead_financial": ["update:lead_financial"],
    "update:lead_financial": [],
    "read:lead_document": ["import:lead_document", "delete:lead_document"],
    "import:lead_document": [],
    "delete:lead_document": [],
    "read:lead_notes": ["create:lead_notes", "update:lead_notes", "delete:lead_notes"],
    "create:lead_notes": [],
    "update:lead_notes": [],
    "delete:lead_notes": [],
    "read:lead_conversation": ["create:lead_conversation"],
    "create:lead_conversation": [],

    "read:client_tab": [],
    "read:client": [
        "create:client",
        "update:client",
        "delete:client",
        "create:client_import",
        "update:client_loan_officer",
        "update:client_loan_officer_own",
        "update:client_agent",
        "update:client_agent_own",
        "update:client_flag",
        "update:client_unflag",
    ],
    "read:client_own": [
        "create:client",
        "update:client",
        "delete:client",
        "create:client_import",
        "update:client_loan_officer",
        "update:client_loan_officer_own",
        "update:client_agent",
        "update:client_agent_own",
        "update:client_flag",
        "update:client_unflag",
    ],
    "create:client": [],
    "update:client": [],
    "delete:client": [],
    "create:client_import": [],
    "update:client_loan_officer": [],
    "update:client_loan_officer_own": [],
    "update:client_agent": [],
    "update:client_agent_own": [],
    "update:client_flag": [],
    "update:client_unflag": [],
    "read:client_overall": [
        "update:client_overall",
        "create:client_credit_scores",
        "update:client_credit_scores",
        "delete:client_credit_scores",
    ],
    "update:client_overall": [],
    "create:client_credit_scores": [],
    "update:client_credit_scores": [],
    "delete:client_credit_scores": [],
    "read:client_program": [
        "create:client_program",
        "update:client_program",
        "delete:client_program",
    ],
    "create:client_program": [],
    "update:client_program": [],
    "delete:client_program": [],
    "read:client_financial": ["update:client_financial"],
    "update:client_financial": [],
    "read:client_document": ["import:client_document", "delete:client_document"],
    "import:client_document": [],
    "delete:client_document": [],
    "read:client_notes": ["create:client_notes", "delete:client_notes", "update:client_notes"],
    "create:client_notes": [],
    "delete:client_notes": [],
    "update:client_notes": [],
    "read:client_conversation": ["create:client_conversation"],
    "create:client_conversation": [],

    "read:employee_tab": [],
    "read:employee": [
        "create:employee",
        "delete:employee",
        "read:employee_overall",
        "update:employee_overall",
        "read:employee_contact",
        "create:employee_notes",
        "update:employee_notes",
        "delete:employee_notes",
        "import:employee_document",
        "delete:employee_document",
        "read:employee_commissions",
        "update:employee_commissions",
    ],
    "read:employee_not_own": [
        "create:employee",
        "delete:employee",
        "read:employee_overall",
        "update:employee_overall",
        "read:employee_contact",
        "create:employee_notes",
        "update:employee_notes",
        "delete:employee_notes",
        "import:employee_document",
        "delete:employee_document",
        "read:employee_commissions",
        "update:employee_commissions",
    ],
    "create:employee": [],
    "delete:employee": [],
    "read:employee_overall": ["update:employee_overall"],
    "update:employee_overall": [],
    "read:employee_contact": [
        "create:employee_notes",
        "update:employee_notes",
        "delete:employee_notes",
        "import:employee_document",
        "delete:employee_document",
    ],
    "create:employee_notes": [],
    "update:employee_notes": [],
    "delete:employee_notes": [],
    "import:employee_document": [],
    "delete:employee_document": [],
    "read:employee_commissions": ["update:employee_commissions"],
    "update:employee_commissions": [],

    "read:activity_tab": [],

    "read:campaign_tab": [],
    "read:campaign_bulk_messages": ["create:campaign_bulk_messages"],
    "create:campaign_bulk_messages": [],
    "read:campaign": ["create:campaign", "delete:campaign", "update:campaign"],
    "create:campaign": [],
    "delete:campaign": [],
    "update:campaign": [],
    "read:campaign_templates": [
        "create:campaign_templates",
        "delete:campaign_templates",
        "update:campaign_templates",
    ],
    "create:campaign_templates": [],
    "delete:campaign_templates": [],
    "update:campaign_templates": [],

    "read:user_tab": [],
    "read:user": [
        "create:user",
        "update:user",
        "delete:user",
        "update:user_role_permissions",
        "update:user_password",
    ],
    "create:user": [],
    "update:user": ["update:user_role_permissions", "update:user_password"],
    "delete:user": [],
    "update:user_role_permissions": [],
    "update:user_password": [],

    "read:calendar_tab": [],
    "read:calendar_appointments": [
        "create:calendar_appointments",
        "update:calendar_appointments",
        "delete:calendar_appointments",
        "create:calendar_appointments_superior",
        "create:calendar_appointments_inferior",
        "read:calendar_appointments_assigned_by",
    ],
    "read:calendar_appointments_own": [
        "create:calendar_appointments",
        "update:calendar_appointments",
        "delete:calendar_appointments",
        "create:calendar_appointments_superior",
        "create:calendar_appointments_inferior",
        "read:calendar_appointments_assigned_by",
    ],
    "create:calendar_appointments": [],
    "update:calendar_appointments": [],
    "delete:calendar_appointments": [],
    "create:calendar_appointments_superior": [],
    "create:calendar_appointments_inferior": [],
    "read:calendar_appointments_assigned_by": [],
    "read:calendar_tasks": [
        "create:calendar_tasks",
        "update:calendar_tasks",
        "delete:calendar_tasks",
        "create:calendar_tasks_other",
        "create:calendar_tasks_superior",
        "create:calendar_tasks_inferior",
        "read:calendar_tasks_assigned_by",
    ],
    "read:calendar_tasks_own": [
        "create:calendar_tasks",
        "update:calendar_tasks",
        "delete:calendar_tasks",
        "create:calendar_tasks_other",
        "create:calendar_tasks_superior",
        "create:calendar_tasks_inferior",
        "read:calendar_tasks_assigned_by",
    ],
    "create:calendar_tasks": [],
    "update:calendar_tasks": [],
    "delete:calendar_tasks": [],
    "create:calendar_tasks_other": [],
    "create:calendar_tasks_superior": [],
    "create:calendar_tasks_inferior": [],
    "read:calendar_tasks_assigned_by": [],

    "read:settings_tab": [],
    "read:settings_dropdowns": ["update:settings_dropdowns"],
    "read:settings_round_robin": ["update:settings_round_robin"],
    "read:settings_creditors": ["update:settings_creditors"],
    "update:settings_dropdowns": [],
    "update:settings_round_robin": [],
    "update:settings_creditors": [],
};
