import { atom } from "recoil";
import ProfilePic from "../../assets/img/avatar-img.png";
import { LEAD_FILTERS } from "../../helpers/LeadsScreen";
export const deleteDocumentPopupState = atom({
    key: "deleteDocumentPopupState",
    default: false,
});

export const deleteUserPopupState = atom({
    key: "deleteUserPopupState",
    default: false,
});

export const deleteTemplatePopupState = atom({
    key: "deleteTemplatePopupState",
    default: false,
});

export const deleteLeadsPopupState = atom({
    key: "deleteLeadsPopupState",
    default: false,
});

export const deleteClientsPopupState = atom({
    key: "deleteClientsPopupState",
    default: false,
});
export const deletePopup = atom({
    key: "deletePopup",
    default: "",
});

export const redFlagLeadPopupState = atom({
    key: "redFlagPopupState",
    default: false,
});

export const userPermissions = atom<string[]>({
    key: "userPermissions",
    default: [],
});

export const loadingAuth = atom({
    key: "loadingAuth",
    default: true,
});

export const userRoles = atom({
    key: "userRoles",
    default: [],
});

export const filterPopup = atom({
    key: "filterPopup",
    default: false,
});


export const userInfo = atom({
    key: "userInfo",
    default: { name: "", profile_image: ProfilePic, person_id: "" },
});
export const programsAtom = atom({
    key: "programsAtom",
    default: [],
});
export const recDocRowDeletePopup = atom({
    key: "documentRowDelete",
    default: { answered: false, showPopup: false, isLoading: false },
});
export const recAlreadyExistPopup = atom({
    key: "alreadyExistPopup",
    default: { showPopup: false, id: "", check: "" },
});
export const recRedFlagPopup = atom({
    key: "redFlagUpdate",
    default: { answered: false, showPopup: false, isLoading: false, note: "" },
});
export const recDeleteLeadPopup = atom({
    key: "deleteLeadPopupState",
    default: { answered: false, showPopup: false, isLoading: false },
});
export const recDeleteTemplatePopup = atom({
    key: "recDeleteTemplatePopup",
    default: { answered: false, showPopup: false, isLoading: false },
});
export const recDeleteCampaignPopup = atom({
    key: "recDeleteCampaignPopup",
    default: { answered: false, showPopup: false, isLoading: false },
});
export const recDeleteClientPopup = atom({
    key: "recDeleteClientPopup",
    default: { answered: false, showPopup: false, isLoading: false },
});
export const recAddToClientPopup = atom({
    key: "recAddToClientPopup",
    default: { answered: false, showPopup: false, isLoading: false },
});
export const leadPreFilters = atom({
    key: "leadPreFilters",
    default: {
        ...LEAD_FILTERS,
    },
});
export const contactPagination = atom({
    key: "contactPagination",
    default: {
        pageLimit: 10,
        page: 1,
        init: true,
    },
});
export const clientsSearchOptions = atom<any>({
    key: "clientsSearchOptions",
    default: undefined,
});
export const leadsSearchOptions = atom<any>({
    key: "leadsSearchOptions",
    default: undefined,
});
export const conversationsSearchOptions = atom<any>({
    key: "conversationsSearchOptions",
    default: undefined,
});
export const campaignsSearchOptions = atom<any>({
    key: "campaignsSearchOptions",
    default: undefined,
});
export const usersSearchOptions = atom<any>({
    key: "usersSearchOptions",
    default: undefined,
});
export const openProgram = atom<any | null>({
    key: "openProgram",
    default: null
})
export const bulkConversationsSearchOptions = atom<any>({
    key: "bulkConversationsSearchOptions",
    default: undefined,
});
export const addProgramPopup = atom<any>({
    key: "addProgramPopup",
    default: "",
});
