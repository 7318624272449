import { LeadFilters, TleadAll } from "../../types";
import { simpleTimeDateFormatter } from "../../utils";

export const toLeadTableFormat = (data: TleadAll[]) => {
    return data.map((e: TleadAll) => ({
        first_name: e?.first_name + " " + e?.last_name,
        source: e?.source,
        re_agent_name: e?.re_agent_name,
        loan_officer_name: e?.loan_officer_name,
        phone: e?.phone,
        created_at: simpleTimeDateFormatter(e?.created_at),
        id: e?.person_id,
        goal: e?.goal,
        checked: false,
    }));
};

export const leadDataToIds = (data: any[]) =>
    data
        // .filter((e) => e?.checked)
        .map((m) => m?.id)
        .join("%");

export const LEAD_FILTERS: LeadFilters = {
    source: "",
    created_at_to: "",
    created_at_from: "",
    last_activity_to: "",
    last_activity_from: "",
    agent_id: "",
    contacted: "",
    preferred_language: "",
    goal: "",
    own_rent: "",
    tags: "",
    pre_qualification_amount_from: "",
    pre_qualification_amount_to: "",
    pre_qualified: "",
    status: ""
};

/**
 * Extract the LeadFilters attributes from any object.
 * @param param0
 * @returns
 */
export const extractFilters = ({
    source = "",
    created_at_to = "",
    created_at_from = "",
    last_activity_to = "",
    last_activity_from = "",
    agent_id = "",
    contacted = "",
    preferred_language = "",
    own_rent = "",
    goal = "",
    tags = "",
    pre_qualification_amount_from = "",
    pre_qualification_amount_to = "",
    pre_qualified = "",
    status = "",
}): LeadFilters => ({
    source,
    created_at_to,
    created_at_from,
    last_activity_to,
    last_activity_from,
    agent_id,
    contacted,
    preferred_language,
    own_rent,
    goal,
    tags,
    pre_qualification_amount_from,
    pre_qualification_amount_to,
    pre_qualified,
    status
});
