import { useEffect } from "react";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { ErrorBoundary } from "./components/errorBoundary";
import { removeLogs } from "./hooks/removeLogs";
import { notificationApiError } from "./utils";
import ScrollTop from "./utils/scrollTop";
import MainScreen from "./views/MainScreen";
import CheckAuth from "./components/auth/CheckAuth";
import { datadogRum } from "@datadog/browser-rum";

function App() {


    /**
     * Disable console.log on production
     */
    useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            removeLogs(false, true);
        }
    }, []);

    /**
     * Initialize Datadog RUM
     */
    useEffect(() => {
        if (!datadogRum.getInternalContext()) {
            datadogRum.init({
                applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || "",
                clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
                site: process.env.REACT_APP_DATADOG_SITE || "",
                service: process.env.REACT_APP_DATADOG_SERVICE || "",
                // Specify a version number to identify the deployed version of your application in Datadog
                // version: '1.0.0',
                env: process.env.NODE_ENV,
                sampleRate: 100,
                replaySampleRate: 100,
                trackInteractions: true,
                defaultPrivacyLevel: "allow",
            });
        }

        datadogRum.startSessionReplayRecording();
    }, []);

    // if (session) {
    //     return session;
    // }

    return (
        <div className="App">
            <CheckAuth>
                <ReactNotifications />
                <BrowserRouter>
                    <ScrollTop>
                        <ErrorBoundary>
                            <SWRConfig
                                value={{
                                    onError: (error, key) => {
                                        if (error?.message === "Failed to fetch") {
                                            notificationApiError("Failed to fetch data.");
                                            // We can send the error to Sentry,
                                            // or show a notification UI.
                                        }
                                    },
                                }}
                            >
                                <div id="tooltip-container"></div>
                                <MainScreen />
                            </SWRConfig>
                        </ErrorBoundary>
                    </ScrollTop>
                </BrowserRouter>
            </CheckAuth>
        </div>
    );
}
export default App;
