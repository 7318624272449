import { Auth0ContextInterface } from "@auth0/auth0-react";
import LoaderWrapper from "../components/loaders/loader";
import jwt_decode from "jwt-decode";

export const checkSession = (
    {
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        getAccessTokenSilently,
        user,
        error,
        logout
    }: Auth0ContextInterface,
    userPermissions: String[],
    setUserPermissions: Function,
    setUserRoles: Function,
    setAuth0Id: Function,
    authId: String,
    setLoading: Function,
    loadingInfo: boolean
) => {
    if (isLoading) {
        setLoading(true);
        return <LoaderWrapper />;
    }
    if (error?.message === 'Invalid IP Access') {
        console.log(error, 'logout3')
        logout()
    };
    if (!isAuthenticated) return <> {loginWithRedirect()} </>;
    if (authId === "")
        setUserData(setUserPermissions, getAccessTokenSilently, setUserRoles, setAuth0Id, authId);
    return null;
};

export const getToken = async ({ getAccessTokenSilently }: Auth0ContextInterface) => {
    return await getAccessTokenSilently();
};

const setUserData = async (
    setPermissions: Function,
    getAccessTokenSilently: Function,
    setUserRoles: Function,
    setAuth0Id: Function,
    authId: String
) => {
    const token = await getAccessTokenSilently();
    const decoded: any = jwt_decode(token);
    if (authId === "") setAuth0Id(decoded.sub);
    // Auth0 only return up to 100 permissions on the token,
    // so we are fetching it from the backend with management API
    // setPermissions(decoded.permissions);
    setUserRoles(decoded["https://crm-vicky.com/roles"]);
};
